@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.phoneNumber {
  composes: marketplaceModalPasswordMargins from global;
  & input {
    /* for Firefox */
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}


.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }

  @media(max-width: 460px) {
    flex-wrap: wrap;
    gap: 30px;
  }
}

.firstNameRoot {
  width: calc(50% - 9px);

  @media(max-width: 460px) {
    width: 100%;
  }
}

.lastNameRoot {
  width: calc(50% - 9px);

  @media(max-width: 460px) {
    width: 100%;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.termBox {
  font-size: 13px;

  &>div {
    &>div {
      & span {
        display: block;
      }
    }
  }
}